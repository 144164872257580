import React, { FC, useState } from 'react';
import { Stepper, Theme, WithStyles, withStyles, Grid, StepContent, createStyles, Step, StepLabel } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useValidation } from './useValidation';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            paddingTop: '52px',
            // 下記の2つのプロパティは上下幅が出たときにスクロールできるようにする設定
            // TODO: Headerとかぶらないように52pxのpaddingを取るのだが、次回修正時に全ての画面で一元管理かつ可変にしておきたい。
            maxHeight: 'calc(100% - 52px)',
            overflow: 'auto',
        },
        rootStepContainer: {
            margin: theme.spacing.unit * 2,
            padding: 0,
        },
        labelStyle: {
            color: `${theme.palette.secondary.main} !important`,
        },
        stepperRoot: {
            display: 'flex',
            justifyContent: 'center',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClosePage: () => void;
}

const Page: FC<Props> = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [formValue, setFormValue] = useState<schema.V1XStoragesOrderCreateRequest>({
        service: schema.V1ObjectsServiceEnum.Box,
        allowedDomain: '',
        body: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailBody),
        cc: [''],
        limit: 30,
        title: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailTitle),
        // 宛先の格納先
        info: [],
        // クラウドストレージが初期値のため、0を設定
        share: 0,
        // ユーザーごとの設定が初期値のため、EachUserを設定
        ordertype: schema.V1ObjectsRegisteredUserEnum.EachUser,
    });
    // 登録先ユーザの記録
    const [selectedOwnerUser, setSelectedOwnerUser] = useState<string>('');
    // メール送信先ユーザの記録
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    // メール送信先グループの記録
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    // 外部メールアドレスの記録
    const [externalMails, setExternalMails] = useState<{ error: string; input: string[] }>({ error: '', input: [] });
    const validateMessage = useValidation(formValue);

    const step = [
        locale.t(locale.keys.memberAuthManagement.orderCreate.stepOne.index),
        locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.index),
        locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.index),
    ];

    const getStepComponent = (step: number) => {
        switch (step) {
            case 0:
                // クラウドストレージ、共有クラウドストレージの選択。接続先選択。ドメイン許可の設定。
                return <StepOne setActiveStep={setActiveStep} formValue={formValue} setFormValue={setFormValue} validateMessage={validateMessage} />;
            case 1:
                // 登録先ユーザ、メール送信先ユーザの選択。外部メールアドレスの入力。
                return (
                    <StepTwo
                        setActiveStep={setActiveStep}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        selectedGroups={selectedGroups}
                        setSelectedGroups={setSelectedGroups}
                        selectedOwnerUser={selectedOwnerUser}
                        setSelectedOwnerUser={setSelectedOwnerUser}
                        externalMails={externalMails}
                        setExternalMails={setExternalMails}
                    />
                );
            case 2:
                // メールのタイトル、本文の入力。メール送信先の確認。
                return <StepThree setActiveStep={setActiveStep} formValue={formValue} setFormValue={setFormValue} validateMessage={validateMessage} />;
            default:
                return <></>;
        }
    };

    if (activeStep > 2) {
        props.onClosePage();
    }

    return (
        <Grid container className={props.classes.root} id={'create-order-root'}>
            <Grid item sm={3} />
            <Grid item sm={12} lg={6}>
                <Stepper activeStep={activeStep} orientation="vertical" className={props.classes.rootStepContainer}>
                    {step.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconProps={{
                                    classes: {
                                        active: props.classes.labelStyle,
                                        completed: props.classes.labelStyle,
                                    },
                                }}
                            >
                                {label}
                            </StepLabel>
                            <StepContent>{getStepComponent(index)}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Page);
